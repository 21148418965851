// 图片资源
import {
	axios
} from '../utils/http.js'

//获取七牛云token
export function PDFToken(data) {
	return axios({
		url: 'v3/UpFileToken',
		method: 'POST',
		data: data
	})
}

export function getQiniuToken(data) {
	return axios({
		url: 'v3/UpFileToken',
		method: 'POST',
        data: data
	})
}